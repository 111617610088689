import React from 'react';
import en from 'react-intl/locale-data/en';

import { IntlProvider, addLocaleData } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import {
  configure,
  Components,
  ThemeOverriderFactory,
  ThemeProvider as EmeraldThemeProvider
} from '@f5/emerald-react';
import { NGINX } from '@f5/emerald-icons';
import { lightTheme } from '@f5cs-ui/core-components';
import store from '../redux/configureStore';

import { emeraldTheme } from '../config/configureTheme';
import localeData from '../locales/en.json';
import App from './App';

const ThemeOverrider = ThemeOverriderFactory(Components);
configure({
  iconLookup: NGINX.lookup
});

// TODO: Update once multiple locale are supported,
// 'en-US' will be fallback if user locale is not supported.
addLocaleData([...en]);

const language = 'en';
const messages = localeData[language];

export default function Provider() {
  return (
    <IntlProvider locale={language} messages={messages}>
      <ReduxProvider store={store}>
        <EmeraldThemeProvider theme={emeraldTheme}>
          <ThemeOverrider>
            <ThemeProvider theme={lightTheme}>
              <App />
            </ThemeProvider>
          </ThemeOverrider>
        </EmeraldThemeProvider>
      </ReduxProvider>
    </IntlProvider>
  );
}
