import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import { Loader } from '@f5cs-ui/core-components';
import { BaseLayout } from '@f5cs-ui/foundation';

import ViewWorldWideThreats from '@f5cs-ui/waf';

import {
  history, 
  formSubmitSuccess as formSubmitSuccessAction,
  resetState as resetStateAction,
} from '@f5cs-ui/core-lib';

const propTypes = {
  formSubmitSuccess: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  resetState: PropTypes.func.isRequired
};

const formName = 'app';

export class App extends React.Component {
  componentDidMount() {
    const { resetState, formSubmitSuccess } = this.props;

    resetState();
    formSubmitSuccess(formName);
  }

  render() {
    const { loading } = this.props;

    return (
      <Router history={history}>
        {!loading ? (
          <BaseLayout>
            <Suspense fallback={<Loader withDimmer />}>
              <Switch>
                <Route component={ViewWorldWideThreats} path="*" />
              </Switch>
            </Suspense>                    
          </BaseLayout>
        ) : (
          <Loader withDimmer />
        )}
      </Router>
    );
  }
}

App.propTypes = propTypes;

function mapStateToProps({ auth, forms, signups }) {
  const formState = forms[formName];

  const { validate } = signups;
  const { newUser: isNewUser } = validate || false;
  return {
    expiresAt: auth.expiresAt,
    isAuthenticated: true,
    loading: !formState || formState.isSubmitting,
    isNewUser,
    isRestrictedUser: false
  };
}

export default connect(mapStateToProps, {
  formSubmitSuccess: formSubmitSuccessAction,
  resetState: resetStateAction
})(App);
