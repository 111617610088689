import TagManager from 'react-gtm-module';
import jwtDecode from 'jwt-decode';
import * as am4core from '@amcharts/amcharts4/core';

import {
  gtmEnabled,
  TrustArc,
  getCookie,
  setCookie,
  X_F5AAS_UI_SESSION,
  X_F5CS_UI_UUID,
  loggingService
  // mockApiEnabled
} from '@f5cs-ui/core-lib';

import { configureAxios } from './config';

/*
 * Set UI UUID cookie. Value is decoded from the 'x-f5aas-ui-session'
 * cookie. The cookie is set as a session cookie
 */
function setUUIdCookie() {
  if (!getCookie(X_F5CS_UI_UUID)) {
    const xF5aasUISessionCookie = getCookie(X_F5AAS_UI_SESSION);
    if (xF5aasUISessionCookie) {
      const sessionCookieValue = jwtDecode(getCookie(X_F5AAS_UI_SESSION));
      const { sub } = sessionCookieValue;
      if (sub) {
        setCookie(X_F5CS_UI_UUID, sub);
      } else {
        loggingService.log(
          `Unable to set '${X_F5CS_UI_UUID}' cookie. 'sub' not found from '${X_F5AAS_UI_SESSION}' cookie.`
        );
      }
    } else {
      loggingService.log(`'${X_F5AAS_UI_SESSION}' cookie not found.`);
    }
  }
}

export default function initApp() {
  // Set UUID cookie
  setUUIdCookie();

  // Initialize TrustArc
  TrustArc.init();

  // Initialize Axios config
  configureAxios();

  // Initialize GTM
  gtmEnabled() &&
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

  // amchart license to hide the amchart watermark icon
  am4core.addLicense('CH57668939'); // amCharts 4 license for version 4.6.0 and up
  am4core.addLicense('MP57668939'); // Maps license for version 4.6.0 and up

  // mockApiEnabled() && configureAxiosMockAdapter();
}
