import { applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { save, load } from 'redux-localstorage-simple';
import { getRootModule, rootSaga } from '@f5cs-ui/core-lib';

import { analyticsMiddleware } from './middleware';

const saveMiddleware = save({
  debounce: 500,
  namespace: 'f5aas',
  states: ['auth.expiresAt', 'accountSelections.accountId']
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  load({
    disableWarnings: true,
    namespace: 'f5aas',
    states: ['auth.expiresAt', 'accountSelections.accountId']
  }),
  // enhancers
  [applyMiddleware(sagaMiddleware, saveMiddleware, analyticsMiddleware)],
  // extensions: sagas, thunks, observables
  [getSagaExtension()],
  // initial modules
  getRootModule()
);

sagaMiddleware.run(rootSaga);

export default store;
