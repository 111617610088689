// This line must be first to ensure polyfills are loaded before the rest of the application
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import Provider from './features/Provider';
import initApp from './init';

import '@f5cs-ui/core-components/dist/fonts/fonts.css';
import 'semantic-ui-f5/semantic/dist/semantic.min.css';
import './index.css';

// Initialization code.
initApp();

ReactDOM.render(<Provider />, document.getElementById('root'));
