import { createGlobalStyle } from 'styled-components';
import themes from '@f5/emerald-themes';
import { FormField, Input, Styles } from '@f5/emerald-react';

const themeDefaults = {
  inject: {},
  color: {
    primary: '#0277bd',
    accent: '#000000',
    gray: '#445472',
    link: '#1379e6',
    status: {
      info: '#1CA1E8',
      success: '#007e00',
      error: '#d43900',
      warning: '#ea6712'
    },
    fill: '#ffffff',
    ink: '#000000',
    focus: {
      outline: 'transparent',
      shadow: '#181B1F'
    }
  },
  visual: {
    roundness: 2,
    border: 1,
    focus: {
      width: 0,
      shadow: 0,
      shadowOpacity: 0.5,
      offset: 0
    }
  },
  fontStacks: {
    headings: `Inter, Helvetica, Arial, sans-serif`,
    body: `Inter, Helvetica, Arial, sans-serif`,
    cta: `Inter, Helvetica, Arial, sans-serif`,
    code: `Inter, "IBM Plex Mono", Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace`,
    special: `Inter, Helvetica, Arial, sans-serif`
  }
};

const emeraldTheme = themes.Customization.ThemeGenerator(
  'Cloud Services',
  themeDefaults
);

// now that we've created a full theme, override some things to bring it in line with the Cloud Services style guide
const buttonColors = emeraldTheme.components.Button.color;

const filledColors = buttonColors.filled.default.states;
filledColors.default.ink = '#FFF';
filledColors.hover.ink = '#FFF';
filledColors.hover.fill = '#0C5C8D';
// filledColors.disabled.ink = '#BDBDBD';
// filledColors.disabled.fill = '#FFF';
// filledColors.disabled.border = '#BDBDBD';

const outlinedColors = buttonColors.outlined.default.states;
outlinedColors.hover.fill = '#EBF1F4';
outlinedColors.disabled.ink = '#BDBDBD';
outlinedColors.disabled.fill = '#FFF';
outlinedColors.disabled.border = '#BDBDBD';

emeraldTheme.components.Button.sizes.small.height = 24;
emeraldTheme.components.Button.sizes.small.fontSize = 14;


emeraldTheme.components.Button.sizes.medium.height = 36;
emeraldTheme.components.Button.sizes.medium.fontSize = 14;
emeraldTheme.components.Button.spacing.medium.inner.horizontal = 20;

emeraldTheme.components.Button.sizes.large.height = 40;
emeraldTheme.components.Button.sizes.large.fontSize = 14;
emeraldTheme.components.Button.spacing.large.inner.horizontal = 32;

// Inject global styles - this should only happen once in the app
const GlobalStyle = createGlobalStyle`
  /* This is the Emerald global styles, which overwrite many of ours in production. Removing them for now, they're mostly legacy and reset styles and we have our own. */
  /* ${Styles.inject.global(emeraldTheme)} */

  ${FormField.cssSelectors.Aligner} {
    margin-bottom: 4px;
  }

  ${FormField.cssSelectors.Label} h5 {
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: default;
    display: inline;
    font-family: Inter;
    font-size: 14px;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }

  ${Input.cssSelectors.Input} {
    margin-top: 4px;
  }

  ${Input.cssSelectors.InputNative} {
    border: 1px solid rgb(178, 188, 195);
    border-radius: 0px;
    /* font-family:Lato, "Helvetica Neue", Arial, Helvetica, sans-serif; */
    /* font-size:14px; */
    /* font-weight:400; */
    height:40px;
    /* line-height:18px; */
    padding: 10px;
  }
`;

export { emeraldTheme, GlobalStyle };
